<template>
  <div>
    <img src="~assets/img/aboutUs/banner.png" class="banner" />
    <div class="w1400 box1">
      <img src="~assets/img/aboutUs/title1.png" />
      <div class="box1_content">
        <div v-for="item in box1List" class="box1_item">
          <img :src="item.img" />
          <div>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box2bg">
      <div class="w1400 box2">
        <img src="~assets/img/aboutUs/title2.png" />
        <div class="box2_content">
          <div v-for="item in box2List" class="box2_item">
            <div class="box2_item_title">
              <span>{{ item.title }}</span>
              <span v-for="server in item.servers">{{ server }}</span>
            </div>
            <div v-if="item.lis" class="box2_item_list">
              <div v-for="li in item.lis">{{ li }}</div>
            </div>
            <span v-else>{{ item.mark }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box3bg">
      <div class="w1400 box3">
        <img src="~assets/img/aboutUs/title3.png" />
        <div class="box3_content">
          <div v-for="item in box3List" class="box3_item">
            <img :src="item.img" />
            <span>{{ item.title }}</span>
            <span>{{ item.content }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="~assets/img/aboutUs/bottom.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      box1List: [
        {
          img: require('assets/img/aboutUs/box1_1.png'),
          title: '行业最底层贸易商活跃且分散散户及板车党居多',
        },
        {
          img: require('assets/img/aboutUs/box1_2.png'),
          title: '散户向加工企业销售无销售发票企业无法进项抵扣',
        },
        {
          img: require('assets/img/aboutUs/box1_3.png'),
          title: '税务监管难度大，步骤多极大增加监管工作量',
        },
        {
          img: require('assets/img/aboutUs/box1_1.png'),
          title: '收购企业全额缴纳增值税企业无营业利润',
        },
      ],
      box2List: [
        {
          title: '信息资讯服务',
          servers: ['INFORMATION CONSULTATION SERVICE'],
          lis: [
            '大宗商品期货现货价格信息',
            '走势分析',
            '采购销售操作指导',
            '广告服务',
            '企业宣传',
          ],
        },
        {
          title: '交易撮合服务',
          servers: ['DEALMAKING SERVICES'],
          lis: [
            '为买卖双方提供货源发布',
            '询价',
            '交易委托',
            '合同跟踪执行',
            '线上交易等综合服务',
          ],
        },
        {
          title: '财税政策咨询落地服务',
          servers: ['FISCAL AND TAX POLICY CONSULTING SERVICES'],
          mark: '利用平台优势协助企业享受地方财税政策支持，帮助地方和企业实现双赢',
        },
        {
          title: '融资保理供应链金融服务',
          servers: ['FINANCING FACTORING SUPPLY CHAIN', 'FINANCIAL SERVICES'],
          mark: '平台提供真实贸易背景数据支持，为客户及资金方提供双向自选，提高贸易周转率及资金使用率，降低成本',
        },
      ],
      box3List: [
        {
          img: require('assets/img/aboutUs/box3_1.png'),
          title: '专注',
          content:
            '大宗商品市场，二十年专注大宗商品行业下金属分类，深刻了解行业内在需求及运行规则',
        },
        {
          img: require('assets/img/aboutUs/box3_2.png'),
          title: '创新',
          content:
            '信息互联网+传统行业，为传统行业插上信息互联网的翅膀，阻力行业全新发展',
        },
        {
          img: require('assets/img/aboutUs/box3_3.png'),
          title: '全面',
          content:
            '全产业链、全面服务，从开采治炼，生产加工、国内外贸易，到回收利用，可会遍布产业链上下游。服务涵盖信息咨询、中介、软件开发、在线交易等多领域',
        },
        {
          img: require('assets/img/aboutUs/box3_4.png'),
          title: '服务',
          content:
            '客户至上、服务为先，一客户需求为研发导向，以提供服务为发展指南',
        },
      ],
    }
  },
}
</script>

<style scoped>
.banner {
  width: 100%;
}
.box1,
.box2,
.box3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 75px 0 100px 0;
  height: 750px;
}
.box1 > img {
  width: 970px;
}

.box1_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 380px;
}
.box1_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  width: 280px;
  height: 360px;
  box-shadow: 0px 0px 10px 0px rgba(122, 122, 122, 0.66);
  border-radius: 8px;
  cursor: pointer;
}
.box1_item:hover {
  transition: linear 0.2s;
  transform: translateY(-20px);
}
.box1_item img {
  width: 100%;
}
.box1_item > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 140px;
  padding: 20px 0;
}
.box1_item div span {
  position: relative;
  padding-left: 20px;
  line-height: 40px;
}
.box1_item div span:hover {
  color: #25449a;
}
.box1_item div span:hover::before {
  position: absolute;
  left: 0px;
  top: 10px;
  content: '';
  width: 3px;
  height: 20px;
  background-color: #25449a;
}

.box2bg {
  width: 100%;
  height: 750px;
  background-image: url('~assets/img/aboutUs/box2_bg.png');
  background-size: 100% 100%;
}

.box2 {
  padding: 90px 0 70px 0;
}
.box2 > img {
  width: 800px;
}
.box2_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 420px;
}
.box2_item {
  width: 280px;
  height: 360px;
  background-color: #2c51b7;
  border-radius: 8px;
  padding: 30px 0px 30px 20px;
  color: #fff;
  cursor: pointer;
}
.box2_item:hover {
  transition: linear 0.2s;
  transform: scale(1.1);
}

.box2_item > span {
  display: inline-block;
  width: 80%;
  font-size: 16px;
  line-height: 50px;
}
.box2_item_title {
  display: flex;
  flex-direction: column;
  line-height: 25px;
}
.box2_item_title span:nth-child(2) {
  margin-top: 10px;
}
.box2_item_title::after {
  content: '';
  width: 55px;
  height: 2px;
  margin-top: 10px;
  background-color: #e83033;
}
.box2_item_title span:nth-child(1) {
  font-weight: 500;
  font-size: 24px;
}
.box2_item_title span:nth-child(2) {
  font-weight: 500;
}

.box2_item_list {
  font-size: 16px;
  line-height: 50px;
}
.box2_item_list div {
  display: flex;
  align-items: center;
}
.box2_item_list div::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  margin-right: 10px;
}

.box3bg {
  width: 100%;
  height: 750px;
}

.box3 {
  height: 700px;
  padding: 90px 0 70px 0;
}
.box3 > img {
  width: 800px;
}
.box3_content {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 420px;
}
.box3_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 100%;
  padding: 0 30px;
}
.box3_item img {
  width: 120px;
}
.box3_item span:nth-of-type(1) {
  margin-top: 25px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.box3_item span:nth-of-type(2) {
  margin-top: 25px;
  line-height: 40px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.bottom {
  position: relative;
  top: 20px;
  width: 100%;
}
.bottom img {
  width: 100%;
}
</style>
